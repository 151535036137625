/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 */
import type {
  Collection,
  CreateAccountParams,
  CreateCollection,
  GYTxId,
  PostNftsBuy200,
  PostNftsBuyBody,
  PostNftsCreateNftBodyItem,
  PostRewards200,
  PostRewardsBody,
  PostRewardsWithdraw200,
  PostRewardsWithdrawBody,
  PostTxSubmitBody,
  RewardPool,
  RewardPoolNft,
  UserInfo
} from './models'


export type getRewardsAssetResponse = {
  data: RewardPool[];
  status: number;
}

export const getGetRewardsAssetUrl = (asset: string,) => {


  return `https://kwarxs.staging.mlabs.city/rewards/${asset}`
}

export const getRewardsAsset = async (asset: string, options?: RequestInit): Promise<getRewardsAssetResponse> => {
  
  const res = await fetch(getGetRewardsAssetUrl(asset),
  {      
    ...options,
    method: 'GET'
    
    
  }

  )
  const data = await res.json()

  return { status: res.status, data }
}



export type postRewardsResponse = {
  data: PostRewards200;
  status: number;
}

export const getPostRewardsUrl = () => {


  return `https://kwarxs.staging.mlabs.city/rewards`
}

export const postRewards = async (postRewardsBody: PostRewardsBody, options?: RequestInit): Promise<postRewardsResponse> => {
  
  const res = await fetch(getPostRewardsUrl(),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8', ...options?.headers },
    body: JSON.stringify(
      postRewardsBody,)
  }

  )
  const data = await res.json()

  return { status: res.status, data }
}



export type postRewardsWithdrawResponse = {
  data: PostRewardsWithdraw200;
  status: number;
}

export const getPostRewardsWithdrawUrl = () => {


  return `https://kwarxs.staging.mlabs.city/rewards/withdraw`
}

export const postRewardsWithdraw = async (postRewardsWithdrawBody: PostRewardsWithdrawBody, options?: RequestInit): Promise<postRewardsWithdrawResponse> => {
  
  const res = await fetch(getPostRewardsWithdrawUrl(),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8', ...options?.headers },
    body: JSON.stringify(
      postRewardsWithdrawBody,)
  }

  )
  const data = await res.json()

  return { status: res.status, data }
}



export type getRewardsNftsResponse = {
  data: RewardPoolNft[];
  status: number;
}

export const getGetRewardsNftsUrl = () => {


  return `https://kwarxs.staging.mlabs.city/rewards/nfts`
}

export const getRewardsNfts = async ( options?: RequestInit): Promise<getRewardsNftsResponse> => {
  
  const res = await fetch(getGetRewardsNftsUrl(),
  {      
    ...options,
    method: 'GET'
    
    
  }

  )
  const data = await res.json()

  return { status: res.status, data }
}



export type postAccountsCreateResponse = {
  data: UserInfo;
  status: number;
}

export const getPostAccountsCreateUrl = () => {


  return `https://kwarxs.staging.mlabs.city/accounts/create`
}

export const postAccountsCreate = async (createAccountParams: CreateAccountParams, options?: RequestInit): Promise<postAccountsCreateResponse> => {
  
  const res = await fetch(getPostAccountsCreateUrl(),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8', ...options?.headers },
    body: JSON.stringify(
      createAccountParams,)
  }

  )
  const data = await res.json()

  return { status: res.status, data }
}



export type getAccountsWalletAddressResponse = {
  data: UserInfo[];
  status: number;
}

export const getGetAccountsWalletAddressUrl = (address: string,) => {


  return `https://kwarxs.staging.mlabs.city/accounts/wallet/${address}`
}

export const getAccountsWalletAddress = async (address: string, options?: RequestInit): Promise<getAccountsWalletAddressResponse> => {
  
  const res = await fetch(getGetAccountsWalletAddressUrl(address),
  {      
    ...options,
    method: 'GET'
    
    
  }

  )
  const data = await res.json()

  return { status: res.status, data }
}



export type getNftsResponse = {
  data: Collection[];
  status: number;
}

export const getGetNftsUrl = () => {


  return `https://kwarxs.staging.mlabs.city/nfts`
}

export const getNfts = async ( options?: RequestInit): Promise<getNftsResponse> => {
  
  const res = await fetch(getGetNftsUrl(),
  {      
    ...options,
    method: 'GET'
    
    
  }

  )
  const data = await res.json()

  return { status: res.status, data }
}



export type postNftsBuyResponse = {
  data: PostNftsBuy200;
  status: number;
}

export const getPostNftsBuyUrl = () => {


  return `https://kwarxs.staging.mlabs.city/nfts/buy`
}

export const postNftsBuy = async (postNftsBuyBody: PostNftsBuyBody, options?: RequestInit): Promise<postNftsBuyResponse> => {
  
  const res = await fetch(getPostNftsBuyUrl(),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8', ...options?.headers },
    body: JSON.stringify(
      postNftsBuyBody,)
  }

  )
  const data = await res.json()

  return { status: res.status, data }
}



export type postNftsCreateResponse = {
  data: unknown[];
  status: number;
}

export const getPostNftsCreateUrl = () => {


  return `https://kwarxs.staging.mlabs.city/nfts/create`
}

export const postNftsCreate = async (createCollection: CreateCollection[], options?: RequestInit): Promise<postNftsCreateResponse> => {
  
  const res = await fetch(getPostNftsCreateUrl(),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8', ...options?.headers },
    body: JSON.stringify(
      createCollection,)
  }

  )
  const data = await res.json()

  return { status: res.status, data }
}



export type postNftsCreateNftResponse = {
  data: unknown[];
  status: number;
}

export const getPostNftsCreateNftUrl = () => {


  return `https://kwarxs.staging.mlabs.city/nfts/create-nft`
}

export const postNftsCreateNft = async (postNftsCreateNftBodyItem: PostNftsCreateNftBodyItem[], options?: RequestInit): Promise<postNftsCreateNftResponse> => {
  
  const res = await fetch(getPostNftsCreateNftUrl(),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8', ...options?.headers },
    body: JSON.stringify(
      postNftsCreateNftBodyItem,)
  }

  )
  const data = await res.json()

  return { status: res.status, data }
}



export type postTxSubmitResponse = {
  data: GYTxId;
  status: number;
}

export const getPostTxSubmitUrl = () => {


  return `https://kwarxs.staging.mlabs.city/tx/submit`
}

export const postTxSubmit = async (postTxSubmitBody: PostTxSubmitBody, options?: RequestInit): Promise<postTxSubmitResponse> => {
  
  const res = await fetch(getPostTxSubmitUrl(),
  {      
    ...options,
    method: 'POST',
    headers: { 'Content-Type': 'application/json;charset=utf-8', ...options?.headers },
    body: JSON.stringify(
      postTxSubmitBody,)
  }

  )
  const data = await res.json()

  return { status: res.status, data }
}



