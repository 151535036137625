import React, { useEffect } from "react";
import { Container } from "./container";
import { Footer } from "./footer";
import { Header } from "./header";
import { useLocation } from "react-router-dom";
import { getConfig } from "../../config";

export const Layout = ({ children }: React.PropsWithChildren) => {
  const config = getConfig();
  const location = useLocation();

  // Get invite code from URL and save to local storage (if present)
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const newInviteCode = query.get("inviteCode");

    if (newInviteCode) {
      localStorage.setItem(config.inviteCode.storageKey, newInviteCode);
    }
  }, [location]);

  return (
    <>
      <Header />
      <Container>{children}</Container>
      <Footer />
    </>
  );
};
