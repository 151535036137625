import { Layout } from "../../components/layout";
import YourNfts from "./yourNfts";

function YourNftsPage() {
  return (
    <Layout>
      <YourNfts />
    </Layout>
  );
}

export default YourNftsPage;
