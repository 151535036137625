import {
  Address,
  AssetClass,
  ChainIndexTxOut,
  CurrencySymbol,
  POSIXTime,
  PageQuery,
  PubKeyHash,
  TxOutRef,
} from "./cardano-types";
import { Metadata, WalletApi } from "lucid-cardano";
import {
  GYAddress,
  GYMintingPolicyId,
  GYTokenName,
  GYValue,
  RewardPool,
} from "./generated/models";

/** Dev use only */
export type SellNft = {
  sellTokens: SellNftToken[];
  sellDeadline: POSIXTime;
  sellPriceAc: AssetClass;
  sellKycCurrency: CurrencySymbol;
  sellTxDeadline: POSIXTime;
};

/** Dev use only */
export type SellNftToken = {
  sellCounter: number;
  sellOperatorPrice: number;
  sellOperatorAddress: Address;
  sellTreasuryPrice: number;
  sellTreasuryAddress: Address;
  sellPrefix: string;
  metadata: Metadata;
};

/** Params to get reward pools by pool NFT asset class */
export type GetRewardPoolsByAssetParams = {
  policyId: string;
  tokenName: string;
};

/** Params used to withdraw reward from the reward pool. */
export type WithdrawRewardParams = {
  /** Reward pool to withdraw from. */
  pool: RewardPool;
  /** Policy ID of the NFT collection. */
  collectionPolicyId: GYMintingPolicyId;
  /** List of NFTs to remint in order to withdraw. */
  nftTokenNames: GYTokenName[];
};

/** Decoded voting UTxO datum. */
export type VotingEscrowDatum = {
  /** List of user's votes */
  votes: [Proposal, Vote];
  /** PKH of users voting. */
  voter: PubKeyHash;
};

/** User's voting UTxO. */
export type UsersVote = {
  /** `TxOutRef` of the voting UTxO. */
  usersVoteTxOutRef: TxOutRef;
  /** `ChainIndexTxOut` of the voting UTxO. */
  usersVoteTxOut: ChainIndexTxOut;
  /** Decoded datum attached to voting UTxO. */
  usersVoteDatum: VotingEscrowDatum;
};

export type Vote = boolean;

export type Proposal = string;

export type VoteProposal = [Proposal, Vote];

/** Params used to cast a vote by the user. */
export type CastVoteParams = {
  /** Governance token, should be set to `KXS` token. */
  govTokenAC: AssetClass;
  /** Amount of governance tokens to be staked in voting UTxO. */
  govTokenAmt: number;
  /** Amount of governance tokens to be staked in voting UTxO. */
  vote: VoteProposal[];
};

/** Params used to recreate user's voting UTxO. */
export type ModifyVoteParams = {
  /** Governance token, should be set to `KXS` token. */
  modGovTokenAC: AssetClass;
  /** Amount of governance tokens to be staked in voting UTxO. */
  modGovTokenAmt: number;
  /** New votes, will override previous votes. */
  modNewDatum: VoteProposal[];
  /** Vote to modify. */
  modVote: UsersVote;
};

export type CreateRewardPoolParams = {
  counter: string;
  dividendsPerPool: number;
  nftCs: GYMintingPolicyId;
  poolCount: number;
  poolTn: GYTokenName;
  rewardValue: GYValue;
}

/** Supported wallets */
export type WalletType = "nami" | "eternl";

/** Information of connected wallet */
export type Wallet = {
  /** Type of connected wallet */
  walletType: WalletType;
  /** User's public key hash */
  pkh: string;
  /** User's optional staking key hash */
  skh?: string;
  /** Bech32 address of wallet */
  bech32: string;
  /** Cbor hex of wallet address */
  hex: string;
  /** CIP 30 API of connected wallet */
  api: WalletApi;
};

export type Asset = {
  /** Asset in format `<currency symbol><hex(token name)>`*/
  unit: string;
  /** Amount of an asset */
  quantity: string;
};

/** Decoded datum attached to NFT marketplace UTxO */
export type MarketplaceDatum = {
  /** Share paid to the treasury */
  treasuryShare: number;
  /** Address of the treasury */
  treasury: string;
  /** Share paid to the protocol operator */
  operatorShare: number;
  /** Address of the protocol operator */
  operator: string;
};

/** Parameters used to get page of NFTs from the marketplace */
export type GetNfts = {
  /** Optional query to get next page, gets first page if not set. */
  gnQuery?: PageQuery<TxOutRef>;
  /** Token used to pay for the NFT. Should be set to `KXS` token. */
  gnPaymentToken: AssetClass;
  /** CurrencySymbol of the KYC Token that is required to buy NFTs. */
  gnKycCurrency: CurrencySymbol;
};

/** NFT specific details */
export type NftInfo = {
  /** `TxOutRef` of UTxO with the NFT. */
  niTxOutRef: TxOutRef;
  /** `ChainIndexTxOut` of UTxO with the NFT. */
  niTxOut: ChainIndexTxOut;
  /** Deocded datum from the UTXO, use it to read price. */
  niDatum: MarketplaceDatum;
};

/** Page of NFT available to buy from the marketplace. */
export type NftPage = {
  /** If set, used to query next page of NFTs. */
  npNextQuery?: PageQuery<TxOutRef>;
  /** Token used to pay for the NFT. Should be set to `KXS` token. */
  npPaymentToken: AssetClass;
  /** List of NFTs on the marketplace.  */
  npNfts: NftInfo[];
};

/** Parameters used to buy an NFT from marketplace. */
export type BuyNftParams = {
  /** NFT symbol to purchase. */
  currencySymbol: string;
};

/** NFT that belongs to the user. */
export type Nft = {
  /** Asset class of given NFT. */
  assetClass: AssetClass;
  /** CIP-25 metadata attached to the minting transaction. */
  metadata: Metadata;
};

// User would just be a mapping of wallet address to MLM Soft account ID.
// We could include email, and other details, but probably best not to for
// privacy reasons.
export type User = {
  walletAddress: {
    pubKeyHash: string;
    stakeKeyHash: string;
  };
  mlmAccountId: number; // The user's account ID in MLM Soft
};

export type SignupParams = {
  wallet: WalletType; // which wallet to use in case user has multiple
  email: string;
  password: string;
  inviteCode: string; // The referral code
  firstName: string;
  lastName: string;
  adaAddress: string;
  mailingAddress: string;
  birthDate: string;
  postalCode: string;
};

export type SignupSuccess = {
  user: User;
};

export type KwarxsErrorCode =
  | "UserCancelled"
  | "ContractFailed"
  | "WalletNotConnected"
  | "OtherFailure";

export type KwarxsError = {
  code: KwarxsErrorCode;
  msg: string;
};

export type SignupError = {
  error: string;
};

export type DefaultRequestParams = {
  addresses: GYAddress[];
  change: GYAddress;
  checkCollateral?: boolean;
  /** @pattern [0-9a-fA-F]+ */
  collateral: string;
};

export class UserCancelledError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UserCancelled";
  }
}

export class ContractFailedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ContractFailed";
  }
}

export class WalletNotConnectedError extends Error {
  constructor(message?: string) {
    super(message ?? "Wallet not connected");
    this.name = "WalletNotConnected";
  }
}

export class UnsupportedWalletError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UnsupportedWallet";
  }
}
