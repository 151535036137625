type AppConfig = {
  blockfrostProjectId: string;
  cardanoNetwork: "Preview" | "Preprod" | "Mainnet";
  inviteCode: {
    default: string;
    storageKey: string;
  };
};

const BLOCKFROST_PROJECT_ID = "REACT_APP_BLOCKFROST_PROJECT_ID";
const CARDANO_NETWORK = "REACT_APP_CARDANO_NETWORK";
const DEFAULT_INVITE_CODE = "REACT_APP_DEFAULT_INVITE_CODE";

const makeErrorMsg = (varName: string) => `Environment variable '${varName}' is missing or invalid!`;

let config: AppConfig | undefined;

export const getConfig = () => {
  if (!config) {
    const blockfrostProjectId = process.env[BLOCKFROST_PROJECT_ID];
    if (!blockfrostProjectId) {
      throw new Error(makeErrorMsg(BLOCKFROST_PROJECT_ID));
    }

    const cardanoNetwork = process.env[CARDANO_NETWORK];
    if (cardanoNetwork !== "Preview" && cardanoNetwork !== "Preprod" && cardanoNetwork !== "Mainnet") {
      throw new Error(makeErrorMsg(CARDANO_NETWORK));
    }

    const defaultInviteCode = process.env[DEFAULT_INVITE_CODE];
    if (!defaultInviteCode) {
      throw new Error(makeErrorMsg(DEFAULT_INVITE_CODE));
    }

    config = {
      blockfrostProjectId,
      cardanoNetwork,
      inviteCode: {
        default: defaultInviteCode,
        storageKey: "inviteCode",
      },
    };
  }

  return config;
};
