import mask from "../../../assets/img/pink.gif";
import styles from "./index.module.scss";

const MembershipBenefits = () => {
  return (
    <>
      <div className={styles.membership__top__image}>
        <img src={mask} alt="pink mask" />
      </div>
      <div className={styles.membership__wrapper}>
        <div className={styles.membership__top} />
        <div className={styles.membership__bottom} />
        <div className={styles.membership__content}>
          <div className={styles.membership__content__header}>
            <h3 className={styles.membership__title}>Kwarxs DAO</h3>
            <h4 className={styles.membership__subtitle}>Membership Benefits</h4>
          </div>
          <div className={styles.membership__benefits}>
            <div className={styles.membership__benefit}>
              <h3 className={styles.membership__benefit__title}>
                Green Energy (R)Evolution
              </h3>
              <p className={styles.membership__benefit__text}>
                Our initial goal for the Kwarxs DAO model is to enable
                fractionalized ownership and management of sustainable energy
                solutions.
              </p>
            </div>
            <div className={styles.membership__benefit}>
              <h3 className={styles.membership__benefit__title}>
                Earn Compound Interest
              </h3>
              <p className={styles.membership__benefit__text}>
                NFTs held within Kwarxs smart contracts may earn APY based upon
                market demand for their DAO-controlled services or other
                subscription products.
              </p>
            </div>
            <div className={styles.membership__benefit}>
              <h3 className={styles.membership__benefit__title}>
                Let Your Voice Be Heard
              </h3>
              <p className={styles.membership__benefit__text}>
                Using our (r)evolutionary dApp, Kwarxs NFT holders may submit
                proposals and exercise their right to vote in a truly democratic
                process.
              </p>
            </div>
          </div>
          <div className={styles.membership__status__container}>
            <div className={styles.membership__status}>
              <div>
                <h3 className={styles.membership__status__title}>
                  Lock in Your
                </h3>
                <h4 className={styles.membership__status__subtitle}>
                  Early Adopter Status
                </h4>
              </div>
              <div className={styles.membership__status__image}>
                <img src={mask} alt="pink mask" />
              </div>
            </div>
            <div className={styles.membership__status__content}>
              <div className={styles.membership__status__box}>
                <h4 className={styles.membership__status__box__title}>
                  Hodl Sun Gowds
                </h4>
                <p className={styles.membership__status__box__text}>
                  DAO membership benefits gained from investing in the first
                  Kwarxs APY NFT collection produce passive income from your
                  first cooperative solar energy project.
                </p>
              </div>
              <div className={styles.membership__status__box}>
                <h4 className={styles.membership__status__box__title}>
                  Be The Change
                </h4>
                <p className={styles.membership__status__box__text}>
                  Our Kwarxs DAO protocol uses the world’s most advanced
                  blockchain technologies to simplify the fractionalization of
                  real-world asset ownership and management, including that of
                  services and organizations.
                </p>
              </div>
              <div className={styles.membership__status__box}>
                <h4 className={styles.membership__status__box__title}>
                  Control Your Future
                </h4>
                <p className={styles.membership__status__box__text}>
                  Kwarxs NFT holders may govern their holdings through our
                  upcoming dApp, made possible by the power and security of
                  Cardano smart contracts. The Kwarxs governance protocol
                  effectively cuts middlemen out of the picture… and out of your
                  pockets.
                </p>
              </div>
            </div>
            <h4 className={styles.membership__tagline}>
              Building Brighter Futures… Together!
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default MembershipBenefits;
