import styles from "./index.module.scss";
import down from "../../assets/icons/down-filled.svg";
import right from "../../assets/icons/right-filled.svg";

interface Props {
  title: string;
  text: JSX.Element;
  isActive: boolean;
  id: string;
  onItemClick: (id: string) => void;
}

const Accordion = ({ title, text, id, isActive, onItemClick }: Props) => {
  const onHeaderClick = () => {
    if (isActive) {
      onItemClick("");
    } else {
      onItemClick(id);
    }
  };

  return (
    <section className={styles.accordion} data-testid="accordion">
      <div
        role="button"
        tabIndex={0}
        onClick={onHeaderClick}
        className={styles.accordion__header}
      >
        <h2 className={styles.accordion__title}>{title}</h2>
        <span className={styles.accordion__button}>
          <img src={isActive ? down : right} alt="plus" />
        </span>
      </div>
      {isActive && (
        <div id="text" className={styles.accordion__text}>
          {text}
        </div>
      )}
    </section>
  );
};

export default Accordion;
