import React, { useRef } from "react";
import styles from "./index.module.scss";
import promo from "../../../assets/Promo KWARXS Video.mp4";
import preview from "../../../assets/img/preview.png";

export const SunGowds = () => {
  const vidRef = useRef<HTMLVideoElement>(null);
  const togglePlay = () => {
    if (vidRef.current === null) return;
    if (vidRef.current.paused) {
      vidRef.current.play();
    } else {
      vidRef.current.pause();
    }
  };
  return (
    <div className={styles.sunGowds}>
      <div className={styles.title}>
        <h2>Sun Gowds Solar Farm</h2>
        <h2 className={styles.soon}>(Dropping Soon!)</h2>
      </div>
      <div className={styles.presentation}>
        <video onClick={togglePlay} poster={preview} ref={vidRef}>
          <source src={promo} type="video/mp4" />
        </video>
      </div>
      <div className={styles.benefits}>
        <div className={styles.benefit}>
          <h4>Take Your Place In The Sun</h4>
          <p>
            NFTs from the Sun Gowds collection provide Kwarxs-enabled DAO
            membership, but that’s not all. These first-of-their-kind NFTs also
            represent fractionalized ownership of a profitable, grid-connected
            solar energy farm located in Roebnitz, Germany.
          </p>
        </div>
        <div className={styles.benefit}>
          <h4>Harness The Power Of The DAO</h4>
          <p>
            Sun Gowds NFT holders own and profit from this business and may
            participate in its cooperative management through the innovative
            Kwarxs DAO platform… all while earning an average APY of 10%-25%.
          </p>
        </div>
      </div>
      <div className={styles.call}>Together, We (are) Matter!</div>
    </div>
  );
};
