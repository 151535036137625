import { useEffect, useState } from "react";
import { getConfig } from "../config";

/**
 * Gets the invite code from the URL and saves it to local storage (if present)
 */
const useInviteCode = (): string => {
  const config = getConfig();
  const [ inviteCode, setInviteCode ] = useState<string | null>(null);

  useEffect(() => {
    setInviteCode(localStorage.getItem(config.inviteCode.storageKey));
  }, []);

  return inviteCode ?? config.inviteCode.default;
};

export default useInviteCode;
