import React, { forwardRef } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";

type Props = {
  className?: string;
  label?: string;
  id?: string;
  error?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef(function Input(
  { className, label, id, error, ...rest }: Props,
  ref: any
) {
  const inputId = id ?? labelToId(label);

  return (
    <div className={styles.container}>
      {label && (
        <label htmlFor={inputId} className={styles.label}>
          {label}:
        </label>
      )}
      <input
        ref={ref}
        id={inputId}
        className={classNames([ styles.input, className ])}
        aria-invalid={error ? "true" : undefined}
        {...rest}
      />
      {error && <p role="alert">{error}</p>}
    </div>
  );
});

function labelToId(label?: string) {
  return label?.toLowerCase().replace(" ", "-") ?? "";
}
