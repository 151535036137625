import { SubmitHandler, useForm } from "react-hook-form";
import { CreateCollection } from "../../api/generated/models";
import { useAddCollectionMutation } from "../../hooks/useNftCollections";
import { Input } from "../../components/input";
import { Button } from "../../components/button";
import styles from "./index.module.scss";
import { Modal } from "../../components/Modal";
import { useToastMessage } from "../../hooks/useToastMessage";

type AddCollectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type FormValues = {
  name: string;
  deadline: string;
  apy: string;
  price: string;
};

/**
 * A form component for adding new NFT collections to the marketplace.
 */
const AddCollectionModal = ({ isOpen, onClose }: AddCollectionModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const { mutateAsync: addCollection, isPending } = useAddCollectionMutation();
  const toast = useToastMessage();

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    try {
      const collection = {
        apy: parseInt(values.apy),
        deadline: new Date(values.deadline).toISOString(),
        name: values.name,
        price: parseInt(values.price),
      };

      await addCollection(collection);

      toast.success("Collection added successfully");
    } catch (e) {
      console.error(e);
      toast.error("Failed to add collection. See console for details");
    }
  };

  return (
    <Modal showModal={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <h2>Add Collection</h2>
        <Input
          {...register("name", { required: "Name is required" })}
          label="Name"
          type="text"
          error={errors.name?.message}
        />
        <Input
          {...register("deadline", { required: "Deadline is required" })}
          label="Deadline"
          type="date"
          error={errors.deadline?.message}
        />
        <Input
          {...register("apy", { required: "APY is required" })}
          label="APY"
          type="number"
          error={errors.apy?.message}
        />
        <Input
          {...register("price", { required: "Price is required" })}
          label="Price (EUR)"
          type="number"
          error={errors.price?.message}
        />
        <Button type="submit">Submit</Button>
      </form>
    </Modal>
  );
};

export default AddCollectionModal;
