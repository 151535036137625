import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CreateRewardPoolParams, useSdkContext } from "../context/SdkContext";
import { Collection, GYValue } from "../api/generated/models";
import { mkPaddedCounter, mkTokenName, tokenNameLength, utf8ToHex } from "../api/utils";
import { collectionsQueryKeys } from "./useNftCollections";
import { mkNamesForNft, mkPoolTokenName } from "../utils/nft";

export type WithdrawRewardsParams = {
  currencySymbol: string;
  tokenName: string;
};

export const rewardPoolsQueryKeys = {
  rewardPoolNfts: "rewardPoolNfts",
  rewardPool: "rewardPool",
};

const queryStaleTime = 1000 * 30; // 30 seconds

type UseRewardPoolsQueryProps = {
  collectionCurrencySymbol: string;
}

export const useRewardPoolsQuery = ({ collectionCurrencySymbol }: UseRewardPoolsQueryProps) =>{
  const { getSdk } = useSdkContext();
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: [ rewardPoolsQueryKeys.rewardPool, collectionCurrencySymbol ],
    staleTime: queryStaleTime,
    queryFn: async () => {
      const sdk = await getSdk();

      // Fetch latest reward pool NFTs
      const rewardPoolNfts = await queryClient.fetchQuery({
        queryKey: [rewardPoolsQueryKeys.rewardPoolNfts],
        queryFn: () => getSdk().then((sdk) => sdk.getRewardNfts()),
        staleTime: queryStaleTime,
      });

      // Find reward pool NFTs that match the collection currency symbol (should only be zero or one?)
      const matchingPoolNfts = rewardPoolNfts.filter((nft) => nft.rewardPoolNftCollection === collectionCurrencySymbol);

      // Fetch reward pools for each matching reward pool NFT.
      // There should likely only be one matching reward pool NFT
      // but we'll handle multiple just in case.
      const promises = matchingPoolNfts.map((poolNft) => {
        return sdk.getRewardPoolsByAsset({
          policyId: poolNft.rewardPoolNftSymbol,
          tokenName: poolNft.rewardPoolNftTokenName,
        });
      });
      const results = await Promise.all(promises);

      return results.flat();
    },
  });
};

export const useRewardPoolNftsQuery = () => {
  const { getSdk } = useSdkContext();
  return useQuery({
    queryKey: [rewardPoolsQueryKeys.rewardPoolNfts],
    queryFn: () => getSdk().then((sdk) => sdk.getRewardNfts()),
  });
};

type AddRewardPoolMutationProps = {
  collection: Collection;
  rewardValue: GYValue;
  dividendsPerPool: number;
  poolCount: number;
}

export const useAddRewardPoolMutation = () => {
  const { getSdk } = useSdkContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      collection,
      rewardValue,
      dividendsPerPool,
      poolCount,
    }: AddRewardPoolMutationProps) => {
      const sdk = await getSdk();

      const { tokenNameHex } = mkPoolTokenName(collection);

      const rewardsPool = {
        counter:  mkPaddedCounter(tokenNameLength - collection.prefixLength, 1),
        dividendsPerPool,
        nftCs: collection.currencySymbol,
        poolCount,
        poolTn: tokenNameHex,
        rewardValue,
      };

      // eslint-disable-next-line
      debugger;

      return sdk.createRewardPools(rewardsPool);
    },
    onSuccess: (_, { collection }) => {
      queryClient.invalidateQueries({ queryKey: [ rewardPoolsQueryKeys.rewardPool, collection.currencySymbol ]});
    },
  });
};

export const useWithdrawRewardsMutation = () => {
  const { getSdk } = useSdkContext();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ currencySymbol, tokenName }: WithdrawRewardsParams) => {
      const sdk = await getSdk();

      // Fetch the reward pool records from the backend
      const rewardNfts = await queryClient.fetchQuery({
        queryKey: [rewardPoolsQueryKeys.rewardPoolNfts],
        queryFn: () => getSdk().then((sdk) => sdk.getRewardNfts()),
        staleTime: queryStaleTime,
      });

      const matchingPoolNfts = rewardNfts.filter((nft) => nft.rewardPoolNftCollection === currencySymbol);

      if (!matchingPoolNfts || matchingPoolNfts.length === 0) {
        throw new Error("Problem withdrawing rewards. No reward pool NFT found.");
      }

      // Select random reward pool nft details to fetch
      const poolNft = pickRandomFrom(matchingPoolNfts);

      // Fetch the actual matching reward pools from onchain
      const rewardPools = await queryClient.fetchQuery({
        queryKey: [ rewardPoolsQueryKeys.rewardPool, currencySymbol ],
        queryFn: () => {
          return sdk.getRewardPoolsByAsset({
            policyId: poolNft.rewardPoolNftSymbol,
            tokenName: poolNft.rewardPoolNftTokenName,
          });
        },
        staleTime: queryStaleTime,
      });

      // Select random reward pool to avoid congestion
      const pool = pickRandomFrom(rewardPools);

      return sdk.withdrawReward({
        pool,
        nftTokenNames: [utf8ToHex(tokenName)],
        collectionPolicyId: currencySymbol,
      });
    },
  });
};

function pickRandomFrom<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}
