import { WalletType } from "../../api";
import { useAuthContext } from "../../context/AuthContext";
import { Button } from "../button";
import { Modal } from "../Modal";
import styles from "./index.module.scss";

type WalletConnectorModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * A modal that allows the user to select between wallets to connect to.
 * Currently only Nami and Eternl are supported.
 */
export const WalletConnectorModal = ({ isOpen, onClose }: WalletConnectorModalProps) => {
  const { connectWallet, isLoading } = useAuthContext();

  const connectAndClose = (wallet: WalletType) => async () =>{
    await connectWallet(wallet);
    onClose();
  };

  return (
    <Modal showModal={isOpen} onClose={onClose}>
      <div className={styles.modalContent}>
        <h2>Connect Wallet</h2>
        <Button
          onClick={connectAndClose("nami")}
          disabled={isLoading}
        >
          Connect with Nami
        </Button>
        <Button
          onClick={connectAndClose("eternl")}
          disabled={isLoading}
        >
          Connect with Eternl
        </Button>
      </div>
    </Modal>
  );
};

export default WalletConnectorModal;
