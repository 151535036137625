import styles from "./index.module.scss";
import nft1 from "../../assets/img/nft-image1.png";
import { useUserNftsQuery } from "../../hooks/useNftCollections";
import NftCard from "../../components/NftCard";
import { Spinner } from "../../components/Spinner";

const YourNfts = () => {
  const { data: userNfts, isLoading } = useUserNftsQuery();

  const renderNfts = () => {
    return userNfts?.map((nft) => {
      const currencySymbol = nft.assetClass.unAssetClass[0].unCurrencySymbol;
      const tokenName = nft.assetClass.unAssetClass[1].unTokenName;
      return (
        <NftCard
          key={`${currencySymbol}${tokenName}`}
          tokenName={tokenName}
          currencySymbol={nft.collection?.currencySymbol ?? ""}
          image={nft1}
          collectionName={nft.collection?.name ?? ""}
          apy={nft.collection?.apy ?? 0}
          price={nft.collection?.price ?? 0}
        />
      );
    });
  };

  return (
    <div className={styles.nfts__wrapper}>
      <div className={styles.nfts__container}>
        <div className={styles.nfts__heading}>
          <div>
            <div>
              <h3 className={styles.nfts__title}>Your NFTs</h3>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.nftcard__container}>
        {isLoading
          ? <Spinner color="grey" />
          : <div className={styles.nfts__nftcard}>{renderNfts()}</div>
        }
      </div>
    </div>
  );
};

export default YourNfts;
