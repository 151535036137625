import { useForm } from "react-hook-form";
import { Button } from "../../components/button";
import { Input } from "../../components/input";
import { Modal } from "../../components/Modal";
import { useAddRewardPoolMutation } from "../../hooks/useRewardPools";
import { adaToUnits } from "../../utils/convertPrice";
import styles from "./index.module.scss";
import { useToastMessage } from "../../hooks/useToastMessage";
import { useCollectionQuery } from "../../hooks/useNftCollections";

type AddRewardPoolsModalProps = {
  collectionCurrencySymbol: string;
  isOpen: boolean;
  onClose: () => void;
};

type FormValues = {
  dividendValue: number;
  dividendsPerPool: number;
  poolCount: number;
}

/**
 * A modal that allows the user to add a reward pool to a collection.
 */
const AddRewardPoolsModal = ({ collectionCurrencySymbol, isOpen, onClose }: AddRewardPoolsModalProps) => {
  const { mutateAsync: addRewardPool, isPending } = useAddRewardPoolMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const { data: collection } = useCollectionQuery({ currencySymbol: collectionCurrencySymbol });
  const toast = useToastMessage();

  const onSubmit = async (values: FormValues) => {
    if (!collection) return;
    try {
      await addRewardPool({
        collection,
        rewardValue: { lovelace: Number(adaToUnits(values.dividendValue)) } ,
        dividendsPerPool: values.dividendsPerPool,
        poolCount: values.poolCount
      });
      onClose();
      toast.success("Reward pools added successfully");
    } catch (e) {
      console.error(e);
      toast.error("Failed to add reward pools. See console for details");
    }
  };

  return (
    <Modal
      showModal={isOpen}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <h2>Add Reward Pools</h2>
        <Input
          {...register("dividendValue", { valueAsNumber: true, required: "ADA value per dividend is required", min: { value: 1, message: "Pool value must be at least 1 ADA" }})}
          label="ADA Value Per NFT"
          type="number"
          error={errors.dividendValue?.message}
        />
        <Input
          {...register("dividendsPerPool", { valueAsNumber: true,required: "Pool value is required", min: { value: 1, message: "There must be at least one dividend per pool" }})}
          label="Number of NFTs Supported Per Pool"
          type="number"
          error={errors.dividendsPerPool?.message}
        />
        <Input
          {...register("poolCount", { valueAsNumber: true, required: "Pool value is required", min: { value: 1, message: "There must be at least one pool" }})}
          label="Pool Count"
          type="number"
          defaultValue={1}
          error={errors.poolCount?.message}
        />
        <Button type="submit">Submit</Button>
      </form>
    </Modal>
  );
};

export default AddRewardPoolsModal;
