import { Collection, PostNftsCreateNftBodyItem } from "../api/generated/models";
import { mkPaddedCounter, mkTokenName, utf8ToHex } from "../api/utils";

// Length of the numberic identifier of each NFT in a collection
const nftNumberLength = 5;

export const mkNamesForNft = (collectionName: string, nftNumber: number) => {
  const abbreviation = abbreviateName(collectionName);
  const paddedNumber = mkPaddedCounter(nftNumberLength, nftNumber);
  const tokenName = mkTokenName(`${abbreviation}-${paddedNumber}-`, 1);
  const tokenNameHex = utf8ToHex(tokenName);
  const name = collectionName + " #" + paddedNumber;

  return { name, tokenName, tokenNameHex };
};

export const mkPoolTokenName = (collection: Collection) => {
  const abbreviation = abbreviateName(collection.name);
  const tokenName = mkTokenName(`${abbreviation}-pool--`, 1);
  const tokenNameHex = utf8ToHex(tokenName);

  return { tokenName, tokenNameHex };
};

/**
 * Takes a collection and details for NFTs to be minted and generates a list of
 * NFTs that each have unique identifying numbers.
 *
 * The token names will be composed of 3 parts:
 *   - An abbreviation of the collection name
 *   - A padded number that identifies the NFT within the collection
 *   - A padded counter that will be used for versioning
 *
 * Example:
 * For a collection named "My Fancy Collection" that has a total of 100 NFTs
 * already minted, the next set of NFTs will have token names:
 *   - "MFC-00101-0000000000000000000000"
 *   - "MFC-00102-0000000000000000000000"
 *   - "MFC-00103-0000000000000000000000"
 * @param collection
 * @param nftCount
 * @returns
 */
export const mkNextNfts = (params: {
  collection: Collection;
  image: string;
  mediaType: string;
  description?: string;
  quantity: number;
}): PostNftsCreateNftBodyItem[] => {
  const startingNumber = params.collection.total + 1;

  // Generate the token names with proper counters
  return Array.from({ length: params.quantity }, (_, i) => {
    const { tokenNameHex, name } = mkNamesForNft(params.collection.name, startingNumber + i);

    return {
      currencySymbol: params.collection.currencySymbol,
      description: params.description ?? "",
      image: params.image,
      mediaType: params.mediaType,
      name,
      tokenName: tokenNameHex,
    };
  });
};

/**
 * Takes a collection name and returns the length to use for the prefix
 * @param collectionName The name of the NFT collection
 * @returns
 */
export const getPrefixLength = (collectionName: string) => {
  const abbreviation = abbreviateName(collectionName);
  return abbreviation.length + nftNumberLength + 2;
};

function abbreviateName(name: string): string {
  const words = name.split(" ");
  return words.map((word) => word[0].toUpperCase()).join("");
}
