import { ReactNode } from "react";
import { toast } from "react-toastify";

export const useToastMessage = () => {
  const success = (message: string | ReactNode ) => {
    toast.success(message);
  };

  const error = (error: any) => {
    let message;
    if (typeof error === "string") {
      message = error;
    } else if (error instanceof Error) {
      message = error.message;
    } else if (error.msg) {
      message = error.msg;
    } else {
      message = JSON.stringify(error);
    }
    toast.error(message);
  };

  return {
    success,
    error,
  };
};
