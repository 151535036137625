/**
 * Get the MIME type of a file from its URL.
 * @param url The URL of the file.
 * @returns The MIME type of the file (e.g. image/jpeg, image/png, etc.)
 * or null if the MIME type is not found.
 */
export function getMimeTypeFromUrl(url: string): string | null {
  const extensionToMimeType: { [key: string]: string } = {
    ".jpg": "image/jpeg",
    ".jpeg": "image/jpeg",
    ".png": "image/png",
    ".gif": "image/gif",
    ".bmp": "image/bmp",
    ".webp": "image/webp",
    ".svg": "image/svg+xml",
    ".ico": "image/vnd.microsoft.icon",
    ".tiff": "image/tiff",
    ".tif": "image/tiff",
  };

  // Extract the file extension from the URL
  const extension = url.split(".").pop()?.toLowerCase();

  // Find the corresponding MIME type
  return extension ? extensionToMimeType[`.${extension}`] : null;
}

/**
 * Validates that a given string is a valid image MIME type.
 * @param mimeType Mime type to validate.
 * @returns True if the MIME type is a valid image MIME type, false otherwise.
 */
export const validateImageMimeType = (mimeType: string): boolean => {
  const validMimeTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/bmp",
    "image/webp",
    "image/svg+xml",
    "image/vnd.microsoft.icon",
    "image/tiff",
  ];

  return validMimeTypes.includes(mimeType);
};

/**
 * Validates that a given string is a URL.
 * @param url The string to validate.
 * @returns True if the string is a valid URL, false otherwise.
 */
export const validateURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
};

/**
 * Fetches the MIME type of a file from its URL.
 * @param url The URL of the file.
 * @returns A promise that resolves to the MIME type of the file (e.g.
 * image/jpeg, image/png, etc.) or null if the MIME type is not found.
 */
export async function fetchMimeType(url: string): Promise<string | null> {
  try {
    // Fetch the file from the URL without downloading the entire file
    const response = await fetch(url, {
      method: "HEAD"
    });

    return response.headers.get("Content-Type");
  } catch (error) {
    return null;
  }
}
