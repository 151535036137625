import { RotatingLines } from "react-loader-spinner";

type SpinnerProps = {
  width?: number;
  color?: string;
}

export const Spinner = ({ width = 42, color = "white" }: SpinnerProps) => {
  return (
    <RotatingLines
      strokeColor={color}
      strokeWidth="5"
      animationDuration="0.75"
      width={width.toString()}
      visible
    />
  );
};
