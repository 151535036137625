import { FaRegSquarePlus } from "react-icons/fa6";
import { useRewardPoolsQuery } from "../../hooks/useRewardPools";
import styles from "./index.module.scss";

type RewardPoolsCellProps = {
  collectionCurrencySymbol: string;
  setAddRewardPoolState: (state: {
    collectionCurrencySymbol: string;
    isModalOpen: boolean;
  }) => void;
};

/**
 * A component that displays the count of reward pools for a given collection
 * along with the ability to add more pools.
 */
const RewardPoolsCell = ({
  collectionCurrencySymbol,
  setAddRewardPoolState,
}: RewardPoolsCellProps) => {
  const { data: rewardPools } = useRewardPoolsQuery({
    collectionCurrencySymbol,
  });

  const onClick = () => setAddRewardPoolState({
    collectionCurrencySymbol,
    isModalOpen: true,
  });

  return (
    <div className={styles.cell} onClick={onClick}>
      {rewardPools?.length ?? ""}
      <FaRegSquarePlus className={styles.icon_add} />
    </div>
  );
};

export default RewardPoolsCell;
