import React from "react";
import { Layout } from "../../components/layout";
import { Hero } from "./hero";
import { SunGowds } from "./sunGowds";
import MembershipBenefits from "./membershipBenefits";
import Faq from "./q&a";

export const Landing = () => {
  return (
    <Layout>
      <Hero />
      <MembershipBenefits />
      <SunGowds />
      <Faq />
    </Layout>
  );
};
