import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import logo from "../../../assets/img/logo.svg";
import walletIcon from "../../../assets/img/wallet.svg";
import menu from "../../../assets/icons/menu.svg";
import { ReactSVG } from "react-svg";
import { Button } from "../../button";
import close from "../../../assets/icons/close.svg";
import SignInModal from "./SignInModal";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { Spinner } from "../../Spinner";
import { getConfig } from "../../../config";
import WalletConnectorModal from "../../WalletConnectorModal";

export const Header = () => {
  const config = getConfig();
  const [ showNav, setShowNav ] = useState(false);
  const [ showWalletConnector, setShowWalletConnector ] = useState(false);
  const [ showSignIn, setShowSignIn ] = useState(false);
  const { wallet, user, verifyWalletConnection, isLoading } = useAuthContext();

  useEffect(() => {
    verifyWalletConnection();
  }, []);


  const renderButtons = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (!wallet) {
      return (
        <Button onClick={() => setShowWalletConnector(true)}>Connect to Wallet ({config.cardanoNetwork})</Button>
      );
    } else if (!user) {
      return <Button onClick={() => setShowSignIn(true)}>Sign up</Button>;
    } else {
      return <ReactSVG src={walletIcon} />;
    }
  };

  return (
    <>
      <div className={styles.background}>
        <div className={styles.container}>
          <div onClick={() => setShowNav(!showNav)} className={styles.menu}>
            <img src={menu} alt="menu" />
          </div>
          <a href="/" className={styles.logo}>
            <img src={logo} alt="KWARXS" />
          </a>
          <div className={styles.actions}>{renderButtons()}</div>
        </div>
        {showNav && (
          <div className={styles.nav__container}>
            <div onClick={() => setShowNav(false)} className={styles.close}>
              <img src={close} alt="close" />
            </div>
            <div className={styles.nav__heading}>
              <p>dashboard</p>
            </div>
            <Link to="/">
              <Button className={styles.nav_button} tertiary>
                Kwarxs
              </Button>
            </Link>
            <Link to="/marketplace">
              <Button className={styles.nav_button} tertiary>
                Sun Gowds
              </Button>
            </Link>
            {user && (
              <Link to="/your-nfts">
                <Button className={styles.nav_button} tertiary>Your NFTs</Button>
              </Link>)}
            <Link to="/marketplace">
              <Button>Join DAO</Button>
            </Link>
          </div>
        )}
      </div>
      <WalletConnectorModal isOpen={showWalletConnector} onClose={() => setShowWalletConnector(false)} />
      <SignInModal open={!user && showSignIn} setOpen={setShowSignIn} />
    </>
  );
};
