import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BuyNftParams, useSdkContext } from "../context/SdkContext";
import {
  Collection,
  CreateCollection,
} from "../api/generated/models";
import { getPrefixLength, mkNextNfts } from "../utils/nft";
import { utf8ToHex } from "../api/utils";

export const collectionsQueryKeys = {
  collections: "collections",
};

const queryStaleTime = 1000 * 30; // 30 seconds

export const useCollectionsQuery = () => {
  const { getSdk } = useSdkContext();
  return useQuery({
    queryKey: [collectionsQueryKeys.collections],
    staleTime: queryStaleTime,
    queryFn: () => getSdk().then((sdk) => sdk.getCollections()),
  });
};

export const useCollectionQuery = ({
  currencySymbol,
}: {
  currencySymbol: string;
}) => {
  const collectionsQuery = useCollectionsQuery();
  return useQuery({
    queryKey: [ collectionsQueryKeys.collections, currencySymbol ],
    staleTime: queryStaleTime,
    queryFn: async () => {
      const collections = collectionsQuery.data;
      return collections?.find(
        (collection) => collection.currencySymbol === currencySymbol
      );
    },
    enabled: !!collectionsQuery.data,
  });
};

type AddCollectionsMutationParams = Omit<CreateCollection, "prefixLength" | "total" | "remaining">;

export const useAddCollectionMutation = () => {
  const { getSdk } = useSdkContext();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params: AddCollectionsMutationParams) => {
      const sdk = await getSdk();

      const collection = {
        ...params,
        prefixLength: getPrefixLength(params.name),
        total: 0,
        remaining: 0,
      };

      return sdk.addCollections([collection]);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [collectionsQueryKeys.collections],
      });
    },
  });
};

type AddNftsMutationParams = {
  collection: Collection;
  image: string;
  mediaType: string;
  description?: string;
  quantity: number;
};

export const useAddNftsMutation = () => {
  const { getSdk } = useSdkContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      collection,
      image,
      mediaType,
      description,
      quantity,
    }: AddNftsMutationParams) => {
      const sdk = await getSdk();

      const nfts = mkNextNfts({
        collection,
        image,
        mediaType,
        description: description ?? "",
        quantity,
      });

      return sdk.addNft(nfts);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [collectionsQueryKeys.collections],
      });
    },
  });
};

export const useBuyNftMutation = () => {
  const { getSdk } = useSdkContext();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ currencySymbol }: BuyNftParams) => {
      const sdk = await getSdk();
      return sdk.buyNft({
        currencySymbol,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [collectionsQueryKeys.collections],
      });
    },
  });
};

export const useUserNftsQuery = () => {
  const { getSdk } = useSdkContext();
  const collectionsQuery = useCollectionsQuery();
  return useQuery({
    queryKey: ["userNfts"],
    staleTime: queryStaleTime,
    queryFn: () => {
      const collections = collectionsQuery.data;
      const currencySymbols = collections?.map(
        (collection) => collection.currencySymbol
      );
      const promises = currencySymbols?.map((currencySymbol) => {
        return getSdk().then((sdk) =>
          sdk.getUsersNfts({ unCurrencySymbol: currencySymbol })
        );
      });

      return Promise.all(promises || []).then((results) => {
        const nfts = results.flat();
        const collectionsMap = collections?.reduce((acc, collection) => {
          return { ...acc, [collection.currencySymbol]: collection };
        }, {} as Record<string, Collection>);

        return nfts.map((nft) => {
          const collection =
            collectionsMap?.[nft.assetClass.unAssetClass[0].unCurrencySymbol];
          return {
            ...nft,
            collection,
          };
        });
      });
    },
    enabled: !!collectionsQuery.data,
  });
};
