import {
  Blockfrost,
  Lucid,
  TxHash,
  Metadata,
  Network,
} from "lucid-cardano";
import { CurrencySymbol } from "./cardano-types";
import {
  BuyNftParams,
  Nft,
  Wallet,
  WithdrawRewardParams,
  SignupParams,
  WalletType,
  UserCancelledError,
  WalletNotConnectedError,
  DefaultRequestParams,
  GetRewardPoolsByAssetParams,
  CreateRewardPoolParams,
} from "./types";
import {
  getAccountsWalletAddress,
  getNfts,
  getRewardsAsset,
  getRewardsNfts,
  postAccountsCreate,
  postNftsBuy,
  postNftsCreate,
  postNftsCreateNft,
  postRewards,
  postRewardsWithdraw,
  postTxSubmit,
} from "./generated/client";
import {
  Collection,
  CreateCollection,
  PostNftsCreateNftBodyItem,
  RewardPool,
  RewardPoolNft,
  UserInfo,
} from "./generated/models";
import { fromHex, mkGYAssetClass } from "./utils";

export * from "./types";
export class Kwarxs {
  private lucid!: Lucid;
  private bfUrl!: string;
  private bfProjectId!: string;
  private partialTxHost!: string;
  connectedWallet!: Wallet | null;

  private constructor() {}

  /** Construct new Kwarxs dapp interface
   * @param bfUrl URL to Blockfrost API
   * @param bfProjectId Blockfrost project ID
   * @param partialTxHost URL to partial-tx server
   * @param network Network to connect to
   * @returns Kwarxs interface
   */
  static async new(
    bfUrl: string,
    bfProjectId: string,
    network: Network
  ): Promise<Kwarxs> {
    const kwarxs = new this();
    const blockfrostInst = new Blockfrost(bfUrl, bfProjectId);
    const lucid = await Lucid.new(blockfrostInst, network);
    kwarxs.lucid = lucid;
    kwarxs.bfUrl = bfUrl;
    kwarxs.bfProjectId = bfProjectId;
    kwarxs.connectedWallet = null;
    return kwarxs;
  }

  /** Disconnect user's wallet
   */
  disconnectWallet(): void {
    this.connectedWallet = null;
  }

  /** Get the first collateral UTxO of the user's wallet
   * @returns Cbor encoded TxOutRef
   */
  private async getWalletCollateral() {
    if (!this.connectedWallet) throw new WalletNotConnectedError();

    const collateralUtxos = await this.connectedWallet.api.experimental.getCollateral();

    // eslint-disable-next-line
    debugger;

    if (collateralUtxos.length === 0) {
      throw new Error("No collateral set for the connected wallet");
    }

    return collateralUtxos[0];
  }

  /** Get the default request params required by the backend for generating transactions
   * @returns Default request params
   */
  private async getDefaultRequestParams(): Promise<DefaultRequestParams> {
    if (!this.connectedWallet) throw new WalletNotConnectedError();

    const collateral = await this.getWalletCollateral();

    return {
      addresses: [this.connectedWallet.hex],
      change: this.connectedWallet.hex,
      checkCollateral: false,
      collateral,
    };
  }

  private async signAndSubmitTx(unsignedTx: string): Promise<TxHash> {
    try {
      if (!this.connectedWallet) throw new WalletNotConnectedError();

      const witnesses = await this.connectedWallet.api.signTx(unsignedTx, true);

      const res = await postTxSubmit({
        unsignedTx,
        witnesses,
      });

      return res.data;
    } catch (e: any) {
      if (e.code === 2) {
        throw new UserCancelledError(e.info);
      }
      throw new Error(JSON.stringify(e));
    }
  }

  /** Fetch token's metadata from ogmios
   * @param asset Asset in format `<currency symbol><hex(token name)>`
   * @returns Metadata of an NFT
   */
  private async fetchMetadata(asset: string): Promise<Metadata> {
    return fetch(`${this.bfUrl}/assets/${asset}`, {
      headers: { project_id: this.bfProjectId },
    })
      .then((res) => res.json())
      .then((res) => res.onchain_metadata);
  }

  /** Check if tx has appeared in block
   * @param txHash Hash of transaction to check
   * @returns Hash of transaction if it has been confirmed, `undefined` otherwise
   */
  private async getTx(txHash: TxHash): Promise<TxHash | undefined> {
    return fetch(`${this.bfUrl}/txs/${txHash}`, {
      headers: { project_id: this.bfProjectId },
    })
      .then((res) => res.json())
      .then((res) => res.hash);
  }

  /** Connects to specified user wallet to the dapp
   */
  async connectToWallet(walletType: WalletType): Promise<void> {
    const wallet = await window.cardano[walletType].enable();

    await this.lucid.selectWallet(wallet);
    const ownWalletAddress = await this.lucid.wallet.address();
    const addressDetails = this.lucid.utils.getAddressDetails(ownWalletAddress);

    this.connectedWallet = {
      walletType,
      pkh: addressDetails.paymentCredential!.hash,
      skh: addressDetails.stakeCredential?.hash,
      bech32: addressDetails.address.bech32,
      hex: addressDetails.address.hex,
      api: wallet,
    };
  }

  async getUsersByWallet(walletType: WalletType): Promise<UserInfo[]> {
    await this.connectToWallet(walletType);
    const res = await getAccountsWalletAddress(this.connectedWallet!.hex);

    return res.data;
  }

  async signUp(params: SignupParams): Promise<UserInfo> {
    if (!this.connectedWallet) throw new WalletNotConnectedError();

    await this.connectToWallet(params.wallet);

    const res = await postAccountsCreate({
      adaAddress: params.adaAddress,
      birthDate: params.birthDate,
      email: params.email,
      firstName: params.firstName,
      inviteCode: params.inviteCode,
      lastName: params.lastName,
      mailingAddress: params.mailingAddress,
      password: params.password,
      postalCode: params.postalCode,
      walletAddress: this.connectedWallet.hex,
    });

    return res.data;
  }

  /**
   * Adds new NFT collections to the marketplace.
   * @param collections List of collections to add
   */
  async addCollections(collections: CreateCollection[]): Promise<void> {
    await postNftsCreate(collections);
  }

  /**
   * Fetches all NFT collections available in the marketplace.
   * @returns List of NFT collections
   */
  async getCollections(): Promise<Collection[]> {
    const res = await getNfts();
    return res.data;
  }

  /**
   * Adds new NFTs to the marketplace.
   * @param nfts List of NFTs to add
   */
  async addNft(nfts: PostNftsCreateNftBodyItem[]): Promise<void> {
    await postNftsCreateNft(nfts);
  }


  /**
   * Buys an NFT using the connected wallet.
   *
   * @param {BuyNftParams} params - The parameters for buying the NFT.
   * @param {string} params.currencySymbol - The currency symbol of the NFT to be bought.
   * @returns {Promise<TxHash>} - A promise that resolves to the transaction hash of the submitted transaction.
   * @throws {Error} - Throws an error if the user wallet is not connected.
   * @throws {UserCancelledError} - Throws an error if the user cancels the transaction signing.
   * @throws {Error} - Throws an error for any other failure during the transaction process.
   */
  async buyNft({ currencySymbol }: BuyNftParams): Promise<TxHash> {
    const defaultParams = await this.getDefaultRequestParams();
    const response = await postNftsBuy({
      currencySymbol,
      ...defaultParams,
    });

    return this.signAndSubmitTx(response.data.unsignedTx);
  }

  /** Get user's NFTs and it's metadata
   * @param curr Currency symbol of NFT collection
   * @returns List of user's NFTs
   */
  async getUsersNfts(curr: CurrencySymbol): Promise<Nft[]> {
    if (!this.connectedWallet) throw new WalletNotConnectedError();

    const utxos = await this.lucid.wallet.getUtxos();
    const res: Nft[] = [];
    for (const utxo of utxos) {
      for (const [ unit, amt ] of Object.entries(utxo.assets)) {
        const cs = unit.substring(0, 56);
        const tn = fromHex(unit.substring(56));
        if (cs === curr.unCurrencySymbol) {
          res.push({
            assetClass: {
              unAssetClass: [{ unCurrencySymbol: cs }, { unTokenName: tn }],
            },
            metadata: await this.fetchMetadata(unit),
          });
        }
      }
    }
    return res;
  }

  /** Create reward pools
   * @returns Hash of submitted transaction
   */
  async createRewardPools(params: CreateRewardPoolParams): Promise<TxHash> {
    const defaultParams = await this.getDefaultRequestParams();
    const res = await postRewards({
      cpCounter: params.counter,
      cpRewardNftsPerPool: params.dividendsPerPool,
      cpRewardNftCs: params.nftCs,
      cpPoolCount: params.poolCount,
      cpPoolTn: params.poolTn,
      cpRewardPerNft: params.rewardValue,
      ...defaultParams,
    });

    return this.signAndSubmitTx(res.data.unsignedTx);
  }

  async getRewardNfts(): Promise<RewardPoolNft[]> {
    const res = await getRewardsNfts();
    return res.data;
  }

  /** Withdraw part of reward from the pool of rewards
 * @param param Reward to withdraw with list of NFTs to remint
 * @returns Hash of submitted transaction
 */
  async withdrawReward(params: WithdrawRewardParams): Promise<TxHash> {
    const defaultParams = await this.getDefaultRequestParams();

    const response = await postRewardsWithdraw({
      wpCollection: params.collectionPolicyId,
      wpRewardNfts: params.nftTokenNames,
      wpPoolRef: params.pool.poolUTxO[0] as string,
      ...defaultParams,
    });

    return this.signAndSubmitTx(response.data.unsignedTx);
  }

  /** List available pools of rewards for the given asset
 */
  async getRewardPoolsByAsset(
    params: GetRewardPoolsByAssetParams
  ): Promise<RewardPool[]> {
    const assetClass = mkGYAssetClass(params);
    const res = await getRewardsAsset(assetClass);

    return res.data;
  }

}
