import styles from "./index.module.scss";
import { Button } from "../button";
import { Spinner } from "../Spinner";
import { useAuthContext } from "../../context/AuthContext";
import { useWithdrawRewardsMutation } from "../../hooks/useRewardPools";
import { makeTransactionUrl } from "../../utils/transaction";
import { useToastMessage } from "../../hooks/useToastMessage";

type NftCardProps = {
  tokenName: string;
  currencySymbol: string;
  image: string | undefined;
  collectionName: string;
  apy: number;
  price: number;
};

const NftCard = ({
  tokenName,
  currencySymbol,
  image,
  collectionName,
  apy,
  price,
}: NftCardProps) => {
  const { user } = useAuthContext();
  const toast = useToastMessage();

  const { mutateAsync: withdrawRewards, isPending: isWithdrawingRewards } =
    useWithdrawRewardsMutation();

  const onWithdrawRewards = async () => {
    try {
      if (!user) {
        // This shouldn't be possible
        throw new Error("Please connect wallet to withdraw rewards.");
      }

      const withdrawTxHash = await withdrawRewards({
        currencySymbol,
        tokenName,
      });

      toast.success(
        <>
          NFT rewards withdrawal{" "}
          <a
            href={makeTransactionUrl(withdrawTxHash)}
            target="_blank"
            rel="noreferrer"
          >
            transaction
          </a>{" "}
          submitted successfully. You should see the rewards in your wallet
          shortly.
        </>
      );
    } catch (error: any) {
      toast.error(error);
    }
  };

  return (
    <div className={styles.nftCard__container}>
      <div className={styles.nftCard__img}>
        <img src={image} alt="nft" />
      </div>
      <h4 className={styles.nftCard__title}>
        <span className={styles.nftCard__text}>{tokenName}</span>
      </h4>
      <table className={styles.nftCard__details}>
        <tbody>
          <tr>
            <td>Collection</td>
            <td>{collectionName}</td>
          </tr>
          <tr>
            <td>Performance</td>
            <td>{apy}% APY</td>
          </tr>
          <tr>
            <td>Price</td>
            <td>{price.toString()} EUR</td>
          </tr>
        </tbody>
      </table>
      <div className={styles.nftCard__button_container}>
        <Button
          disabled={!user || isWithdrawingRewards}
          className={styles.nftCard__button}
          onClick={onWithdrawRewards}
        >
          {isWithdrawingRewards ? <Spinner width={20} /> : <>Withdraw Reward</>}
        </Button>
      </div>
    </div>
  );
};

export default NftCard;
