import { useState } from "react";
import green from "../../../assets/img/green.gif";
import Accordion from "../../../components/accordion";
import { faqContent } from "../../../context/FaqContext";
import styles from "./index.module.scss";

const Faq = () => {
  const [ currentItemId, setCurrentItemId ] = useState(faqContent[0].id);
  return (
    <div className={styles.faq__wrapper}>
      <div className={styles.faq__container}>
        <div className={styles.faq__image}>
          <img src={green} alt="green mask" />
        </div>
        <div className={styles.body}>
          <div className={styles.faq__content}>
            <div className={styles.faq__content__header}>
              <h3 className={styles.faq__content__title}>
                You Have Questions?
              </h3>
              <h4 className={styles.faq__content__subtitle}>
                We Have Answers!
              </h4>
              <h3 className={styles.faq__content__mobile__title}>Questions?</h3>
              <h4 className={styles.faq__content__mobile__subtitle}>
                Answers!
              </h4>
            </div>
            <div className={styles.faq__accordion}>
              {faqContent.map((item) => (
                <Accordion
                  key={item.id}
                  title={item.title}
                  text={item.text}
                  isActive={currentItemId === item.id}
                  id={item.id}
                  onItemClick={setCurrentItemId}
                />
              ))}
            </div>
          </div>
          <h4 className={styles.faq__tagline}>Let Your Crypto Work For You!</h4>
        </div>
      </div>
    </div>
  );
};

export default Faq;
